$(".expertise-video-thumbnail").click(function() {
  $(".expertise-video-iframe").fadeIn();
});

$(".single-treatment-img").click(function() {
  $(".expertise-video-iframe").fadeIn();
});

window.addEventListener("load", function() {
  let startVideo = function() {
    let video = document.querySelector('#heroVideo');

    if(video) {
      video.play().then(function() {
        video.setAttribute('autoplay', true);
      });
    }
  };

  setTimeout(startVideo, 1000);
});
