import Modal from 'bootstrap/js/dist/modal';
import Cookies from 'js-cookie';

// popup variables
const options = {backdrop: 'static', keyboard: false};
const promoPopup = new Modal($('#promo-popup'), options);

// promo popup functions
$(document).on('click', '.close-button', () => {
  const popupIsSeen = true;
  if (popupIsSeen) {
    promoPopup.hide();
    Cookies.set('popupIsSeen', popupIsSeen, { expires: 3 });
  }
});

if (Cookies.get('popupIsSeen') != "true") {
  promoPopup.show();
}
