/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Slider home-treatments */
var treatments_slide = new Swiper('.hometreatments-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 0,
  centeredSlides: false,
  loop: true,
  breakpoints: {
    992: {
      loop: false,
    },
  },
});

/* Slider home-edito */
var homeedito_thumbs_swiper = new Swiper('.homeedito-thumbs-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 0,
});

var homeedito_swiper = new Swiper('.homeedito-swiper', {
  loop: false,
  slidesPerView: 'auto',
  spaceBetween: 18,
  navigation: {
    nextEl: '.homeedito-next',
    prevEl: '.homeedito-prev',
  },
  thumbs: {
    swiper: homeedito_thumbs_swiper,
  },
});

/* Slider institute */
var institute_swiper = new Swiper('.institute-swiper', {
  loop: true,
  slidesPerView: 1.4,
  spaceBetween: 15,
  grabCursor: true,
  centeredSlides: true,
  autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    breakpoints: {
      992: {
      spaceBetween: 30,
      slidesPerView: 2.5,
    },
      1200: {
      slidesPerView: 3.5,
    },
  },
});

/* Slider team */
var team_swiper = new Swiper('.team-swiper', {
  loop: true,
  slidesPerView: 'auto',
  spaceBetween: 24,
  grabCursor: true,
  navigation: {
    nextEl: '.team-next',
    prevEl: '.team-prev',
  },
});

/* Slider members-institute */
var members_swiper = new Swiper('.members-swiper', {
  loop: true,
  slidesPerView: 'auto',
  navigation: {
    nextEl: '.members-next',
    prevEl: '.members-prev',
  },
});

/* Slider reviews */
var reviews_slide = new Swiper('.reviews-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 50,
  centeredSlides: false,
  navigation: {
    nextEl: '.reviews-next',
    prevEl: '.reviews-prev',
  },
});

/* Slider experts */
var experts_swiper = new Swiper('.experts-swiper', {
  slidesPerView: 'auto',
  spaceBetween: 18,
  centeredSlides: false,
  navigation: {
    nextEl: '.experts-next',
    prevEl: '.experts-prev',
  },
});

/* eslint-enable no-unused-vars */
